/*
테마 컬러.
#22577E 
#5584AC 
#95D1CC
#F6F2D4
*/

@import "~react-image-gallery/styles/css/image-gallery.css";
.App {
}

.photo {
    width: 100%;
}
.nav-bar {
    margin-bottom: 1vh;
}
.nav-item {
    padding: 20px;
}
.img {
    width: 100%;
    height: 100%;
}
.header {
    background-color:#5584AC;
    color: white;
    padding: 30px 20px 30px 20px;
}
.footer {
    text-align: center;
    background-color:#eee;
    color: black;
    padding: 30px 20px 30px 20px;
}
.content {
    margin: 20px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 80px;
    object-fit: cover;
    display: block;
}